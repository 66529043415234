import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Weekly favourites`}</h2>
    <p><strong parentName="p">{`Software`}</strong>{` - This week's favourite software is...`}<a parentName="p" {...{
        "href": "https://ueli.app/#/"
      }}>{`Ueli`}</a>{`! I have been searching for an app like this for ages and I have finally found it!! Ueli helps you to quickly launch an app or google something or convert currencies. You can make shortcuts for websites like Gmail and you can also have it execute scripts! I know the in Windows you can just use the native Windows Search and it works great but one of the major disadvantages of it is that it uses Bing. So yeah, not cool.`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2926ff2d0d264c8f6e55d1dad626a0a2/8ae78/Quote-10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABeklEQVQoz61R21LCMBD1/79GBtQW5AHlDi0UBGe8jIIpTZq2CApDQXLcpF4Yxwcf3JmTk2yyJ3s5wj+aUgpHUIAX3aESDlHx+6jH16iGV8RjXASDjPkAl2KYnaMxKoFnUJMjVKMR6rMr7PZvRtQIdue3OBEd2MJBUfZwFnRQjHqwQxenszYs0SU4OPXb5k77NFuc/KEDa9bBcrc+EExuUPBbKMeeCbK4Y8Rt4sK0gXPya+izRinsoRx5KMk+SsJFnjXB0/mnoEKLj5Gf1GEzytLv0qMeTh6bKEv6YNpC5XlE3ESBAnOTmvk8N6X3VMXxQxX5p8ZBhmTO4g451shKojJs6eI88bI9lW1Jx4hONhKPK4HpJiJIsG1sWCNVu+8M+3oocmgafUHNv6Sh1OKs+XooZebCXdz/bcp6SZIEQgiIMETAAzDfRxzH4JyDMYbVemUC9mpPUF9QP/ZfJa9eX7CYx1g+zw2/LJfYbrfYEdI0Naw+gtRB8G/2Do5nmNERaZC8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/2926ff2d0d264c8f6e55d1dad626a0a2/5a190/Quote-10.png",
            "srcSet": ["/static/2926ff2d0d264c8f6e55d1dad626a0a2/772e8/Quote-10.png 200w", "/static/2926ff2d0d264c8f6e55d1dad626a0a2/e17e5/Quote-10.png 400w", "/static/2926ff2d0d264c8f6e55d1dad626a0a2/5a190/Quote-10.png 800w", "/static/2926ff2d0d264c8f6e55d1dad626a0a2/8ae78/Quote-10.png 1096w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    How to do a simple search and replace in
    <a href="https://twitter.com/hashtag/Roam?src=hash&amp;ref_src=twsrc%5Etfw">
      #Roam
    </a>
    :).
    <a href="https://twitter.com/hashtag/roamcult?src=hash&amp;ref_src=twsrc%5Etfw">
      #roamcult
    </a>
    Select the text, convert it into a page, change the page title and delete
    the page. <a href="https://t.co/u1NO3a8ljO">pic.twitter.com/u1NO3a8ljO</a>
  </p>
  &mdash; CatoMinor (@CatoMinor3)
  <a href="https://twitter.com/CatoMinor3/status/1335509543535652865?ref_src=twsrc%5Etfw">
    December 6, 2020
  </a>
    </blockquote>
    <h2>{`Books that I will be finishing this week`}</h2>
    <p>{`Sadly this week I was not able to read a book nor was I able to take some notes. So this week's reading is the same as last week. I will be reading Book/Atomic Habits and I will be publishing my notes on Book/The Psychology of Money`}</p>
    <center>
      <p><a parentName="p" {...{
          "href": "https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299/ref=sr_1_2?dchild=1&keywords=Atomic+Habits&qid=1606640108&sr=8-2"
        }}><span parentName="a" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "inline-block",
              "width": "325px",
              "height": "500px"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "800px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "150.99999999999997%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7IW4rhVkibbH/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARAhASIf/aAAgBAQABBQJtfAWNjet8uUR2zqAL/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BhT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEh/8QAGxAAAgMAAwAAAAAAAAAAAAAAACEBEDERQoH/2gAIAQEABj8CUGVpt6KRcenUUH//xAAgEAEAAgECBwAAAAAAAAAAAAABABEhMVEQYXGBkaGx/9oACAEBAAE/IWmo9pny66MNImzgOb7iDNyoXywuFnmjb+obQE//2gAMAwEAAgADAAAAEP8AGkz/xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQFx/9oACAEDAQE/EOkU2V//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhUf/aAAgBAgEBPxDXy2QwOdv/xAAgEAEAAgIBBAMAAAAAAAAAAAABABEhMUFhscHwcZHR/9oACAEBAAE/EK0S3Yk+MO4UrFm6J7ylGkvOdwdZFtaazzFD9493K8Ha8QhTLVXe5YUr6jsdjpARh6niVOF6yJT3Uo8N0HM//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "atomic-habits",
                "title": "atomic-habits",
                "src": "/static/f64cf646b03f14e46327e4f9e833f51a/4b190/atomic-habits.jpg",
                "srcSet": ["/static/f64cf646b03f14e46327e4f9e833f51a/e07e9/atomic-habits.jpg 200w", "/static/f64cf646b03f14e46327e4f9e833f51a/066f9/atomic-habits.jpg 400w", "/static/f64cf646b03f14e46327e4f9e833f51a/4b190/atomic-habits.jpg 800w", "/static/f64cf646b03f14e46327e4f9e833f51a/e5166/atomic-habits.jpg 1200w", "/static/f64cf646b03f14e46327e4f9e833f51a/b17f8/atomic-habits.jpg 1600w", "/static/f64cf646b03f14e46327e4f9e833f51a/53d10/atomic-habits.jpg 1696w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></a></p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      